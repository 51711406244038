var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow staff-utilization-modal",
            scrollable: "",
          },
          on: { hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  _vm.canApplyAll
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.applyToAll,
                            callback: function ($$v) {
                              _vm.applyToAll = $$v
                            },
                            expression: "applyToAll",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.state.errorShow,
                callback: function ($$v) {
                  _vm.$set(_vm.state, "errorShow", $$v)
                },
                expression: "state.errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.$t("error.attention_required")) + " "),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("staff.field.staff"),
                "label-for": "staffname",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c(
                    "b-form-input",
                    _vm._g(
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true },
                            expression: "{ required: true }",
                          },
                        ],
                        staticClass: "form-label",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("staff.select_staff"),
                          readonly: "",
                          "data-vv-as": _vm.$t("staff.field.staff"),
                          "data-vv-name": "staffName",
                          "data-vv-delay": "500",
                        },
                        model: {
                          value: _vm.staffName,
                          callback: function ($$v) {
                            _vm.staffName = $$v
                          },
                          expression: "staffName",
                        },
                      },
                      _vm.canList() ? { click: _vm.staffSelectorOpen } : {}
                    )
                  ),
                  _vm.canList()
                    ? _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            { on: { click: _vm.staffSelectorOpen } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("button.select"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showStaffNameError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v("  " + _vm._s(_vm.errors.first("staffName")) + " "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            _vm._l(_vm.customFieldsFiltered, function (field, index) {
              return _c(
                "b-col",
                { key: index, attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    [
                      field.type !== "Boolean"
                        ? _c("template", { slot: "label" }, [
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(_vm._s(field.displayName)),
                            ]),
                            field.description
                              ? _c(
                                  "span",
                                  [
                                    _c("font-awesome-icon", {
                                      style: {
                                        color:
                                          "var(--form-control-placeholder)",
                                        fontSize: "0.9em",
                                      },
                                      attrs: {
                                        id: _vm.componentId + "_" + field.name,
                                        icon: ["far", "circle-question"],
                                      },
                                    }),
                                    _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          target:
                                            _vm.componentId + "_" + field.name,
                                          triggers: "hover",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(field.description) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _c("CustomField", {
                        attrs: { componentId: _vm.componentId, field: field },
                        model: {
                          value: _vm.customData[field.name],
                          callback: function ($$v) {
                            _vm.$set(_vm.customData, field.name, $$v)
                          },
                          expression: "customData[field.name]",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm.isGenericStaff && _vm.supportsUnit
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("staff.field.unit"),
                    "label-for": "staffUnit",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "b-input-group-prepend",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { id: "UNIT_SUBTRACT" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.unitAddMinus(-1)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "minus"] },
                              }),
                              _c("b-popover", {
                                attrs: {
                                  target: "UNIT_SUBTRACT",
                                  placement: "top",
                                  triggers: "hover",
                                  content: _vm.$t("staff.button.unit_subtract"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        staticClass: "form-label",
                        attrs: { type: "text" },
                        model: {
                          value: _vm.staffUnit,
                          callback: function ($$v) {
                            _vm.staffUnit = $$v
                          },
                          expression: "staffUnit",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { id: "UNIT_ADD" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.unitAddMinus(1)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "plus"] },
                              }),
                              _c("b-popover", {
                                attrs: {
                                  target: "UNIT_ADD",
                                  placement: "top",
                                  triggers: "hover",
                                  content: _vm.$t("staff.button.unit_add"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    {
                      staticClass: "alert-danger form-field-alert",
                      class: { "d-block": _vm.showStaffUnitError },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle-exclamation"] },
                      }),
                      _vm._v(
                        "  " + _vm._s(_vm.errors.first("staffUnit")) + " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("staff.field.utilization"),
                "label-for": "staffUtilization_" + _vm.id,
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c(
                        "b-button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.utilizationAddMinus(-0.1)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "minus"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    attrs: { id: "staff-utilization_" + _vm.id, type: "text" },
                    on: { blur: _vm.utilizationFormat },
                    model: {
                      value: _vm.staffUtilization,
                      callback: function ($$v) {
                        _vm.staffUtilization = $$v
                      },
                      expression: "staffUtilization",
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.utilizationAddMinus(0.1)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "plus"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showStaffUtilizationError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v(
                    "  " + _vm._s(_vm.errors.first("staffUtilization")) + " "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.state.staffSelectorShow
        ? _c("StaffSelectorModal", {
            attrs: { show: _vm.state.staffSelectorShow, multiple: false },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "staffSelectorShow", $event)
              },
              ok: _vm.staffSelectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }